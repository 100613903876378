import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/helpers';
import { DashboardGuard } from './auth/helpers/dashboard.guards';

const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./main/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [DashboardGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('./main/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'vehiculos',
    loadChildren: () =>
      import('./main/cars/cars.module').then((m) => m.CarsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'parkeo',
    loadChildren: () =>
      import('./main/parkings/parkings.module').then((m) => m.ParkingsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'estacionamientos',
    loadChildren: () =>
      import('./main/enclosures/enclosures.module').then(
        (m) => m.EnclosuresModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'pagos',
    loadChildren: () =>
      import('./main/payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'alertas',
    loadChildren: () =>
      import('./main/alerts/alerts.module').then((m) => m.AlertsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'descuentos',
    loadChildren: () =>
      import('./main/discounts/discounts.module').then(
        (m) => m.DiscountsModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'reservaciones',
    loadChildren: () =>
      import('./main/reservations/reservations.module').then(
        (m) => m.ReservationsModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'aeropuerto',
    loadChildren: () =>
      import('./main/aeropuerto/aeropuerto.module').then(
        (m) => m.AeropuertoModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Airport'],
    },
  },
  {
    path: 'auditorio',
    loadChildren: () =>
      import('./main/auditorio/auditorio.module').then(
        (m) => m.AuditorioModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Auditorium'],
    },
  },
  {
    path: 'jw-polanco',
    loadChildren: () =>
      import('./features/polanco/polanco.module').then(
        (m) => m.JwPolancoModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'JWPolanco'],
    },
  },
  {
    path: 'anzures',
    loadChildren: () =>
      import('./features/anzures/anzures.module').then((m) => m.AnzuresModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Anzures'],
    },
  },
  {
    path: 'monumento',
    loadChildren: () =>
      import('./features/monument/monument.module').then(
        (m) => m.MonumentModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Monument'],
    },
  },
  {
    path: 'colonia-juarez',
    loadChildren: () =>
      import('./features/mapfre/mapfre.module').then((m) => m.MapfreModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Varsovia'],
    },
  },
  {
    path: 'hyatt',
    loadChildren: () =>
      import('./features/hyatt/hyatt.module').then((m) => m.HyattModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin', 'Hyatt'],
    },
  },
  {
    path: 'mapa',
    loadChildren: () =>
      import('./main/maps/maps.module').then((m) => m.MapsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'errors',
    loadChildren: () =>
      import('./main/errors/errors.module').then((m) => m.ErrorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./main/faq/faq.module').then((m) => m.FaqModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'forms',
    loadChildren: () =>
      import('./main/forms/forms.module').then((m) => m.FormsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./main/support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'historial-de-busquedas',
    loadChildren: () =>
      import('./main/search-history/search-history.module').then((m) => m.SearchHistoryModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'notificaciones',
    loadChildren: () =>
      import('./main/notifications/notifications.module').then((m) => m.NotificationsModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'incidentes',
    loadChildren: () =>
      import('./main/incidents/incidents.module').then((m) => m.IncidentModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'reportes',
    loadChildren: () =>
      import('./main/report/report.module').then((m) => m.ReportModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./main/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/errors/error-404', //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
