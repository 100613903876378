import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'home',
    type: 'item',
    url: 'dashboard',
    role: ['Admin'],
  },
  {
    id: 'users-driver',
    title: 'Conductores',
    icon: 'user-plus',
    type: 'item',
    url: 'usuarios/conductores',
    role: ['Admin'],
  },
  {
    id: 'users-host',
    title: 'Anfitriones',
    icon: 'user-check',
    type: 'item',
    url: 'usuarios/anfitriones',
    role: ['Admin'],
  },
  {
    id: 'estacionamientos',
    title: 'Estacionamientos',
    icon: 'trello',
    type: 'item',
    url: 'estacionamientos',
    role: ['Admin'],
  },
  {
    id: 'parkeos',
    title: 'Parkeos',
    icon: 'package',
    type: 'item',
    url: 'parkeo',
    role: ['Admin'],
  },
  {
    id: 'cars',
    title: 'Vehículos',
    icon: 'truck',
    type: 'item',
    url: 'vehiculos',
    role: ['Admin'],
  },
  {
    id: 'reservations',
    title: 'Reservaciones',
    icon: 'calendar',
    type: 'item',
    url: 'reservaciones',
    role: ['Admin'],
  },
  {
    id: 'reports',
    title: 'Reportes',
    icon: 'trello',
    type: 'item',
    url: 'reportes',
    role: ['Admin'],
  },
  {
    id: 'aeropuerto',
    title: 'Aeropuerto',
    icon: 'calendar',
    type: 'item',
    url: 'aeropuerto',
    role: ['Admin', 'Airport'],
  },
  {
    id: 'auditorio',
    title: 'Auditorio',
    icon: 'calendar',
    type: 'item',
    url: 'auditorio',
    role: ['Admin', 'Auditorium'],
  },
  {
    id: 'jw-polanco',
    title: 'Jw Polanco',
    icon: 'calendar',
    type: 'item',
    url: 'jw-polanco',
    role: ['Admin', 'Polanco'],
  },
  {
    id: 'anzures',
    title: 'Anzures',
    icon: 'calendar',
    type: 'item',
    url: 'anzures',
    role: ['Admin', 'Anzures'],
  },
  {
    id: 'monumento',
    title: 'Monumento',
    icon: 'calendar',
    type: 'item',
    url: 'monumento',
    role: ['Admin', 'Monument'],
  },
  {
    id: 'Varsovia',
    title: 'Varsovia',
    icon: 'calendar',
    type: 'item',
    url: 'colonia-juarez',
    role: ['Admin', 'Varsovia'],
  },
  {
    id: 'Hyatt',
    title: 'Hyatt',
    icon: 'calendar',
    type: 'item',
    url: 'hyatt',
    role: ['Admin', 'Hyatt'],
  },
  {
    id: 'incidents',
    title: 'Incidentes',
    icon: 'flag',
    type: 'item',
    url: 'incidentes',
    role: ['Admin'],
  },
  {
    id: 'payments',
    title: 'Pagos',
    icon: 'dollar-sign',
    type: 'item',
    url: 'pagos',
    role: ['Admin'],
  },
  {
    id: 'alerts',
    title: 'Alertas',
    icon: 'message-square',
    type: 'item',
    url: 'alertas',
    role: ['Admin'],
  },
  {
    id: 'discounts',
    title: 'Descuentos',
    icon: 'bookmark',
    type: 'item',
    url: 'descuentos',
    role: ['Admin'],
  },
  {
    id: 'maps',
    title: 'Mapa',
    icon: 'map-pin',
    type: 'item',
    url: 'mapa',
    role: ['Admin'],
  },
  {
    id: 'faq',
    title: 'FAQs',
    icon: 'help-circle',
    type: 'item',
    url: 'faq',
    role: ['Admin'],
  },
  {
    id: 'forms',
    title: 'Formularios',
    icon: 'alert-circle',
    type: 'item',
    url: 'forms',
    role: ['Admin'],
  },
  {
    id: 'support',
    title: 'Soporte',
    icon: 'alert-triangle',
    type: 'item',
    url: 'support',
    role: ['Admin'],
  },
  {
    id: 'search-history',
    title: 'Historial de busquedas',
    icon: 'search',
    type: 'item',
    url: 'historial-de-busquedas',
    role: ['Admin'],
  },
  {
    id: 'notifications',
    title: 'Notificaciones',
    icon: 'volume',
    type: 'item',
    url: 'notificaciones',
    role: ['Admin'],
  },
];
